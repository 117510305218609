<template>
  <!--
    Lists Information about the API and the SPA.
  -->
  <div class="versionInfoDetail">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <div
        v-if="settings"
        class="row"
      >
        <div class="col-12 col-xl-6">
          <Portlet
            :title="$t('applicationInfo.runningSoftwareInformation')"
            icon="running"
            class="mb-4"
          >
            <table class="defaultTable">
              <thead>
                <tr>
                  <th>{{ $t('application') }}</th>
                  <th>{{ $t('version') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="settings.spaVersion">
                  <td>{{ $t('spa') }}</td>
                  <td>{{ settings.spaVersion }}</td>
                </tr>
                <tr v-if="settings.apiVersion">
                  <td>{{ $t('api') }}</td>
                  <td>{{ settings.apiVersion }}</td>
                </tr>
              </tbody>
            </table>
          </Portlet>

          <LocalStorageInformation class="mb-4" />
          
          <NetworkAdapterInformation />
        </div>
        
        <div class="col-12 col-xl-6">
          <Portlet
            :title="$t('applicationInfo.databaseInformation')"
            icon="database"
            class="mb-4"
          >
            <font-awesome-icon
              class="gray"
              icon="database"
            />
            <h5 class="d-inline-block mt-1 pl-3">
              {{ $t('installation') }} & {{ $t('configuration') }}
            </h5>
            <table class="defaultTable mb-4">
              <colgroup>
                <col width="100">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('applicationInfo.upToDate') }}</th>
                  <td>
                    <font-awesome-icon
                      :class="isConfigurationDatabaseReadyIconColor"
                      :icon="isConfigurationDatabaseReadyIcon"
                    />
                    <span :class="`${ isConfigurationDatabaseReadyIcon } pl-2`">{{ isConfigurationDatabaseReadyText }}</span>
                  </td>
                </tr>
                <tr v-if="!isConfigurationDatabaseReady && settings.configurationMigrationResponse != null">
                  <th>{{ $t('applicationInfo.pending') }}</th>
                  <td>
                    <div
                      id="accordionFour"
                      class="panel-group"
                    >
                      <div class="panel">
                        <div class="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordionFour"
                            href="#collapseFour"
                          >{{ $t('migrations') }}</a>
                        </div>
                        <div
                          id="collapseFour"
                          class="panel-collapse collapse show"
                        >
                          <div class="panel-body">
                            <br>
                            <ul class="list-group">
                              <li
                                v-for="(migration, index) in settings.configurationMigrationResponse.pendingMigrations"
                                :key="`VersionInfoDetail-allMigrations-listItem-${ index }`"
                                class="list-group-item"
                              >
                                {{ migration }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="!isConfigurationDatabaseReady">
                  <th>{{ $t('migration') }}</th>
                  <td>
                    <button
                      class="btn btn-sm btn-primary mb-3"
                      @click="migrateConfigurationDatabase"
                    >
                      {{ $t('migrate') }}
                    </button>
                    <p
                      v-if="configurationMigrationResult"
                      class="red"
                    >
                      {{ configurationMigrationResult.error }}
                    </p>
                  </td>
                </tr>
                <tr v-if="settings.vppMigrationResponse != null">
                  <th>{{ $t('applicationInfo.applied') }}</th>
                  <td>
                    <div
                      id="accordionThree"
                      class="panel-group"
                    >
                      <div class="panel">
                        <div class="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordionThree"
                            href="#collapseThree"
                          >{{ $t('migrations') }}</a>
                        </div>
                        <div
                          id="collapseThree"
                          class="panel-collapse collapse"
                        >
                          <div class="panel-body">
                            <br>
                            <ul class="list-group">
                              <li
                                v-for="(migration, index) in settings.configurationMigrationResponse.appliedMigrations"
                                :key="`VersionInfoDetail-allMigrations-listItem-${ index }`"
                                class="list-group-item"
                              >
                                {{ migration }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <font-awesome-icon
              class="gray"
              icon="database"
            />
            <h5 class="d-inline-block mt-1 pl-3">
              {{ $t('videoPostProcessing.title') }}
            </h5>
            <table class="defaultTable">
              <colgroup>
                <col width="100">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('applicationInfo.upToDate') }}</th>
                  <td>
                    <font-awesome-icon
                      :class="isVppDatabaseReadyIconColor"
                      :icon="isVppDatabaseReadyIcon"
                    />
                    <span :class="`${ isVppDatabaseReadyIcon } pl-2`">{{ isVppDatabaseReadyText }}</span>
                  </td>
                </tr>
                <tr v-if="isVppDatabaseReady == false && settings.vppMigrationResponse != null">
                  <th>{{ $t('applicationInfo.pending') }}</th>
                  <td>
                    <div
                      id="accordionOne"
                      class="panel-group"
                    >
                      <div class="panel">
                        <div class="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordionOne"
                            href="#collapseOne"
                          >{{ $t('migrations') }}</a>
                        </div>
                        <div
                          id="collapseOne"
                          class="panel-collapse collapse show"
                        >
                          <div class="panel-body">
                            <br>
                            <ul class="list-group">
                              <li
                                v-for="(migration, index) in settings.vppMigrationResponse.pendingMigrations"
                                :key="`VersionInfoDetail-allMigrations-listItem-${ index }`"
                                class="list-group-item"
                              >
                                {{ migration }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="!isVppDatabaseReady">
                  <th>{{ $t('applicationInfo.migration') }}</th>
                  <td>
                    <button
                      class="btn btn-sm btn-primary mb-3"
                      @click="migrateVppDatabase"
                    >
                      {{ $t('migrate') }}
                    </button>
                    <p
                      v-if="vppMigrationResult"
                      class="red"
                    >
                      {{ vppMigrationResult.error }}
                    </p>
                  </td>
                </tr>
                <tr v-if="settings.vppMigrationResponse != null">
                  <th>{{ $t('applicationInfo.applied') }}</th>
                  <td>
                    <div
                      id="accordionTwo"
                      class="panel-group"
                    >
                      <div class="panel">
                        <div class="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordionTwo"
                            href="#collapseTwo"
                          >{{ $t('migrations') }}</a>
                        </div>
                        <div
                          id="collapseTwo"
                          class="panel-collapse collapse"
                        >
                          <div class="panel-body">
                            <br>
                            <ul class="list-group">
                              <li
                                v-for="(migration, index) in settings.vppMigrationResponse.appliedMigrations"
                                :key="`VersionInfoDetail-allMigrations-listItem-${ index }`"
                                class="list-group-item"
                              >
                                {{ migration }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Portlet>
          <RequestReport />
        </div>
      </div>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ApplicationInfoDetail',
  components: {
    LocalStorageInformation: () => import('@/components/LocalStorageInformation.vue'),
    NetworkAdapterInformation: () => import('@/components/Endpoint/NetworkAdapterInformation.vue'),
    RequestReport: () => import('@/components/Reporting/RequestReport.vue')
  },
  data () {
    return {
      settings: {
        spaVersion: this.$spaVersion,
        apiVersion: null
      },
      configurationMigrationResult: null,
      vppMigrationResult: null,
      loading: true,
    }
  },
  computed: {
    isConfigurationDatabaseReady: function () {
      return this.checkSettings(true, true)
    },
    isConfigurationDatabaseReadyIcon: function () {
      return this.checkSettings(true, false) ? 'check' : 'times';
    },
    isConfigurationDatabaseReadyIconColor: function () {
      return this.checkSettings(true, false) ? 'green' : 'red';
    },
    isConfigurationDatabaseReadyText: function () {
      return this.checkSettings(true, false) ? 'okay' : 'failed';
    },
    isVppDatabaseReadyText: function () {
      return this.checkSettings(false, false) ? 'okay' : 'failed';
    },
    isVppDatabaseReady: function () {
      return this.checkSettings(false, true)
    },
    isVppDatabaseReadyIcon: function () {
      return this.checkSettings(false, false) ? 'check' : 'times';
    },
    isVppDatabaseReadyIconColor: function () {
      return this.checkSettings(false, false) ? 'green' : 'red';
    }
  },
  mounted () {    
    this.getSettings(); 
  },
  methods: {
    checkSettings (isConfig, checkMigration) {
      if(this.settings == null) {
        return false;
      }

      if(isConfig) {
        if(!this.settings.configurationMigrationResponse) {
          return false;
        }
        if(!this.settings.configurationMigrationResponse.isDatabaseReady) {
          return false;
        }
        if(checkMigration && !this.settings.configurationMigrationResponse.pendingMigrations) {
          return false;
        }
      } else {
        if(!this.settings.vppMigrationResponse) {
          return false;
        }
        if(!this.settings.vppMigrationResponse.isDatabaseReady) {
          return false;
        }
        if(checkMigration && !this.settings.vppMigrationResponse.pendingMigrations) {
          return false;
        }
      }

      return true;
    },
    getSettings () {
      this.axios.all([
        this.axios.get('/Setting/GetAll'),
        this.axios.get('/Setting/CheckConfigurationMigrations'),
        this.axios.get('/Setting/CheckVideoPostProcessingMigrations')
      ]).then(
        this.axios.spread((settingResponse, configurationMigrationResponse, vppMigrationResponse) => {
          if (settingResponse && settingResponse.data && settingResponse.data.apiVersion) {
            this.settings.apiVersion = settingResponse.data.apiVersion;
          }
          if (configurationMigrationResponse && configurationMigrationResponse.data) {
            this.settings.configurationMigrationResponse = configurationMigrationResponse.data;
          }
          if (vppMigrationResponse && vppMigrationResponse.data) {
            this.settings.vppMigrationResponse = vppMigrationResponse.data;
          }
        }
      ))
      .finally(() => {
        this.loading = false;
      });
    },
    migrateConfigurationDatabase () {
      this.axios.get('/Setting/MigrateConfigurationDatabase')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.configurationMigrationResult = response.data;
        })
        .catch((error) => {
          if (error == null) {
            return;
          }
          if (error.response == null) {
            return;
          }
          if (error.response.data == null) {
            return;
          }
          this.configurationMigrationResult = error.response.data;
        });
    },
    migrateVppDatabase () {
      this.axios.get('/Setting/MigrateVideoPostProcessingDatabase')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.vppMigrationResult = response.data;
        })
        .catch((error) => {
          this.vppMigrationResult = error.response.data;
        });
    }
  }
}
</script>

<style scoped>
li {
  overflow-wrap: break-word;
}
</style>
